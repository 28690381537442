<template>
  <d-ctg-analizator-page
    v-if="measId"
    :id="measId"
  />
  <div
    v-else
    class="mt-4 ml-2"
  >
    Нет информации, по какому замеру нужен отчет!
  </div>
</template>

<script>

export default {
  name: 'CtgAnalizatorPAge',
  page: {
    title: 'CRM Doctis - Анализатор КТГ замера',
  },
  data() {
    return {
      measId: '',
    };
  },

  mounted() {
    this.measId = this.$route.query.id;
  },
};
</script>
